import type { ComponentProps } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Modal as AntModal } from 'antd';

import { baseCancelLinkButton } from 'styles/component-styles/buttons';
import * as CoverImageStyles from 'components/layout/Common/ProfileCover/CoverImageStyles';

export const Modal = styled(AntModal)`
  ${({ theme }) => css`
    .ant-modal-content {
      border-radius: 8px;
    }

    .ant-modal-close-icon {
      svg {
        font-size: 1.3rem;
        font-weight: 600;
        color: ${theme.colors.gray10};
      }
    }
  `}
`;
export const Header = styled.div`
  margin-bottom: 14px;
`;
export const Cover = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: -1.7em -1.7em 0 -1.7em;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    ${CoverImageStyles.ContentWrapper} {
      padding: 1.5em;

      > h2 {
        color: ${theme.colors.white};
        text-shadow: 2px 2px 5px ${theme.colors.gray10};
      }

      > button {
        z-index: 1;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        padding: 0;
        color: ${theme.colors.white};

        :hover {
          color: ${theme.colors.gray2};
        }

        svg {
          font-size: 1.7rem;
          filter: drop-shadow(2px 2px 2px ${theme.colors.gray10});
        }
      }
    }

    ${CoverImageStyles.Mask} ${CoverImageStyles.Button} {
      top: 1.4rem;
      right: 4rem;
    }
  `}
`;
export const PhotoUpload = styled.div`
  display: flex;
  margin-top: -30px;

  button {
    display: block;
    ${({ theme }) => theme.typography.fontSmall()};
  }
  .add-photo {
    color: ${({ theme }) => theme.colors.gray10};
    border-color: ${({ theme }) => theme.colors.gray6};
  }
  .remove-photo {
    padding: 0;
    color: ${({ theme }) => theme.colors.blue};
    :hover,
    :active,
    :focus {
      color: ${({ theme }) => theme.colors.blue7};
    }

    svg {
      margin-right: 5px;
    }
  }
`;
export const UploadIconWrapper = styled.div`
  z-index: 1;
  display: inline-block;
  width: 90px;
  height: 90px;
  padding: 22px 15px;
  margin: 0 12px 12px 0;
  background-color: #fff;
  border: dashed 1px ${({ theme }) => theme.colors.primary};
  border-radius: 50%;

  svg {
    width: 60px;
    height: 38px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
export const ControlContainer = styled.div`
  margin-top: 2.5rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LinkButton = styled((props: Omit<ComponentProps<typeof Button>, 'type'>) => (
  <Button {...props} type="link" />
))`
  ${baseCancelLinkButton};
`;
