import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { rgba } from 'polished';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Button = styled.button`
  ${({ theme }) => css`
    padding: 0.312em 1em;
    font-weight: 600;
    color: ${theme.colors.gray4};
    cursor: pointer;
    background: ${rgba(theme.colors.gray10, 0.5)};
    border: 0;
    border-radius: 2px;

    :hover {
      color: ${theme.colors.primary};
    }
  `}
`;

type CoverImgProps = { maxHeight: number };

export const CoverImg = styled(LazyLoadImage, { shouldForwardProp: isPropValid })<CoverImgProps>`
  ${props => css`
    width: 100%;
    max-width: 720px;
    max-height: ${props.maxHeight}px;
    object-fit: cover;
  `}
`;

export const ContentWrapper = styled.div`
  position: absolute;
  inset: 0;
`;

export const Mask = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: all 0.15s ease-in-out;

  :hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }

  ${Button} {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
`;
