// GLOBAL
import React from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import { Avatar } from 'antd';
import type { AvatarProps } from 'antd/lib/avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import useAuth from 'hooks/useAuth';
import getProfilePhoto from 'lib/getProfilePhoto';
import { useSelector } from 'store';
import { isUserOnline } from 'store/selectors/usersSelectors';

// LOCAL COMPONENTS
import Badge from '../Badge/Badge';

import * as S from './ProfileImageStyles';

//#region TYPES
export type SizeType = number | keyof typeof _sizes;

type Profile = {
  profileId: number;
  profileType: ProfileTypes;
  name: string;
  mediaId?: number;
};

export type ProfileImageProps = {
  profile: Profile;
  checkOnline?: boolean;
  className?: string;
  size?: SizeType;
  badge?: number;
  to?: string;
  style?: React.CSSProperties;
  skipOnlineCheck?: boolean;
};
//#endregion

// PRIVATE
const _sizes: Record<'xs' | 'sm' | 'lg' | 'xlg', AvatarProps['size']> = {
  xs: 32,
  sm: 'large',
  lg: 48,
  xlg: 80,
};

export const profileTypes = {
  user: 1,
  page: 2,
} as const;

const getSize = (size: SizeType) => {
  if (typeof size === 'number') return size;
  return _sizes[size] || 'large';
};

const ProfileImage = ({
  profile,
  checkOnline = false,
  className = '',
  size = 'sm',
  badge = 0,
  to,
  style,
}: ProfileImageProps) => {
  const { userInfo } = useAuth();
  const isOnline = useSelector(state => isUserOnline(state, profile.profileId), shallowEqual);
  const src = getProfilePhoto(
    profile.profileId,
    profile.profileType,
    profile.mediaId ?? userInfo.user.mediaId
  );

  const renderAvatar = () => {
    if (to)
      return (
        <Link to={to}>
          <Avatar size={getSize(size)} src={src}>
            {profile.name.substring(0, 1)}
          </Avatar>
        </Link>
      );

    return (
      <Avatar size={getSize(size)} src={src}>
        {profile.name.substring(0, 1)}
      </Avatar>
    );
  };

  return (
    <S.Container className={className} style={style}>
      {badge > 0 && <Badge count={badge} size="sm" />}
      {renderAvatar()}
      {checkOnline && profile.profileId !== userInfo.user.userId && (
        <S.OnlineCheck online={isOnline} size={size}>
          <FontAwesomeIcon icon={faCircle} className="check-online" />
        </S.OnlineCheck>
      )}
    </S.Container>
  );
};

export default ProfileImage;
