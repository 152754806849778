import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePollHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { shallow } from 'zustand/shallow';
import { v4 } from 'uuid';

import { useAuth, usePermissions } from 'hooks';
import { useDispatch } from 'store';
import { changeProfile, initNewPostStore } from 'store/actions/newPostActions';
import type { StoreKey } from 'store/types/newPostTypes';
import { PROFILE_TYPES } from 'lib/constants';

import { useGroupAdmins, useGroupInfo } from 'components/Groups/GroupStore';
import ComposerModal, { ComposerModalRef } from './ComposerModal/ComposerModal';
import {
  UploadButtonProps,
  UploadFileButton,
  UploadImageButton,
  UploadVideoButton,
} from '../Attachments/AttachmentsActions';
import ComposerProfile from './ComposerProfileWrapper';
import { useComposerStore } from './composerContext';
import * as S from './ComposerStyles';

type ComposerProps = {
  storeKey: StoreKey;
};

export default function Composer({ storeKey }: ComposerProps) {
  const { t } = useTranslation();
  const { networkSettings, networkFunctions } = useAuth();
  const [canCreatePoll] = usePermissions('CreatePoll');

  const composerModalRef = useRef<ComposerModalRef>(null);

  useComposer({ storeKey });

  const [attachments, setAttachments] = useComposerStore(
    state => [state.attachments, state.setAttachments],
    shallow
  );
  const setSurveyOpen = useComposerStore(state => state.setSurveyOpen);

  const fileUploaderHandler: UploadButtonProps['onFileUploaded'] = (
    acceptedFiles,
    fileType,
    rejectedFiles
  ) => {
    const { maxPhotosPermittedInPost } = networkSettings;

    if (acceptedFiles.length > 0) {
      // resetPostError();
      // this.setState({ errorMessage: '' });

      const totalFileCount = acceptedFiles.length + attachments.length;
      let allowedFiles = acceptedFiles;

      if (totalFileCount >= maxPhotosPermittedInPost && fileType === 'image') {
        allowedFiles = acceptedFiles.slice(0, maxPhotosPermittedInPost - attachments.length);
      }

      const newFiles = allowedFiles.map<PostFile>(file => ({
        blob: file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
      }));

      setAttachments(newFiles, fileType);

      composerModalRef.current?.open();
    } else if (rejectedFiles && rejectedFiles.length > 0) {
      // this.resetFiles();
      // this.setState({
      //   errorMessage: t('files.forbiddenAttachment'),
      // });
    }
  };

  return (
    <>
      <S.ComposerPanel>
        <S.ComposerContainer>
          <ComposerProfile storeKey={storeKey} />
          <S.ComposerButton onClick={composerModalRef.current?.open}>
            {t('feed:editor.placeholder')}
          </S.ComposerButton>
        </S.ComposerContainer>

        <S.ControlsWrapper>
          <Row justify="center">
            <Col span={6} style={{ alignContent: 'center' }}>
              <UploadImageButton content={t('common.photo')} onFileUploaded={fileUploaderHandler} />
            </Col>
            <Col span={6} style={{ alignContent: 'center' }}>
              <UploadVideoButton content={t('common.video')} onFileUploaded={fileUploaderHandler} />
            </Col>
            <Col span={6} style={{ alignContent: 'center' }}>
              <UploadFileButton
                content={t('common.attachment')}
                onFileUploaded={fileUploaderHandler}
              />
            </Col>
            {networkFunctions.polls && canCreatePoll && (
              <Col span={6}>
                <Tooltip title={t('survey.survey')}>
                  <Button
                    size="large"
                    type="text"
                    block
                    icon={<FontAwesomeIcon icon={faSquarePollHorizontal} className="anticon" />}
                    onClick={() => {
                      setSurveyOpen(true);
                      composerModalRef.current?.open();
                    }}
                  >
                    {t('common.survey')}
                  </Button>
                </Tooltip>
              </Col>
            )}
          </Row>
        </S.ControlsWrapper>
      </S.ComposerPanel>
      <ComposerModal storeKey={storeKey} ref={composerModalRef} />
    </>
  );
}

type UseComposerHook = {
  storeKey: ComposerProps['storeKey'];
};

function useComposer({ storeKey }: UseComposerHook) {
  const { userId } = useAuth();
  const [createPublicPost] = usePermissions('CreatePublicPost');

  const dispatch = useDispatch();

  const { page, owner } = useGroupInfo();
  const groupAdmins = useGroupAdmins();

  const userProfile = useMemo(
    () => ({
      profileId: userId,
      profileType: PROFILE_TYPES.user,
      name: '',
    }),
    [userId]
  );

  const groupProfile = useMemo(
    () => ({
      profileId: page?.pageId,
      name: page?.name,
      profileType: PROFILE_TYPES.group,
    }),
    [page?.pageId, page?.name]
  );

  const isAdmin = useMemo(
    () => userId === owner?.userId || groupAdmins?.some(adm => userId === adm.user.userId),
    [groupAdmins, owner?.userId, userId]
  );

  const initFeedComposer = useCallback(() => {
    dispatch(initNewPostStore(storeKey, createPublicPost ? 'public' : 'followers'));
    dispatch(changeProfile(storeKey, userProfile));
  }, [createPublicPost, dispatch, storeKey, userProfile]);

  const initGroupComposer = useCallback(() => {
    dispatch(initNewPostStore(storeKey));
  }, [dispatch, storeKey]);

  useEffect(() => {
    if (storeKey === 'NEW_POST') {
      initFeedComposer();
    } else {
      initGroupComposer();
    }
  }, [initFeedComposer, initGroupComposer, storeKey]);

  useEffect(() => {
    if (storeKey === 'NEW_POST_GROUP') {
      dispatch(changeProfile(storeKey, isAdmin ? groupProfile : userProfile));
    }
  }, [dispatch, groupProfile, isAdmin, storeKey, userProfile]);
}
