import { useEffect } from 'react';
import { CamelCase } from 'type-fest';
import PubSub from 'pubsub-js';
import { onMessageListener } from 'lib/config/configureFirebase';
import { NotificationMessageType } from 'lib/constants';
import { camelCase } from 'change-case';
import { isJSON } from 'lib/helper';

export type MessageType = CamelCase<keyof typeof NotificationMessageType>;

type MessageListener<T = any> = (messageType: MessageType, data?: T) => void;

const Messaging = () => {
  useEffect(() => {
    let unsub: Awaited<ReturnType<typeof onMessageListener>>;

    // console.log('unsub');

    (async () => {
      unsub = await onMessageListener(payload => {
        if (payload.data?.notificationMessageType) {
          const value = isJSON(payload.data.data1)
            ? JSON.parse(payload.data.data1)
            : payload.data.data1;
          PubSub.publish(payload.data?.notificationMessageType, value);
        }
      });
    })();

    return () => {
      unsub?.();
    };
  }, []);

  return null;
};

export default Messaging;

export function makeSubscription<T = any>(
  messageType: NotificationMessageType,
  messageCallback: MessageListener<T>
) {
  const messageTypeString = NotificationMessageType[messageType];

  // console.log('message');

  const subscription = PubSub.subscribe(messageTypeString, (message, data) => {
    messageCallback(camelCase(message) as MessageType, data);
    // console.log('subscription');
  });

  return () => PubSub.unsubscribe(subscription);
}
