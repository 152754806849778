import React, { FormEvent, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import type { EditorState, ContentBlock } from 'draft-js';
import type { MentionData } from '@draft-js-plugins/mention';
import loadable from '@loadable/component';
import { Button, Carousel, Tooltip } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faGlobe,
  faLock,
  faUserFriends,
  faCircle,
  faUserShield,
  faChevronRight,
  faChevronLeft,
  faEye,
  faPaperclip,
} from '@fortawesome/pro-solid-svg-icons';
import { v4 } from 'uuid';
import { debounce } from 'lodash/fp';
import { stateToHTML } from 'draft-js-export-html';

import { resetPosts } from 'store/actions/postsActions';

import { useAuth, usePermissions } from 'hooks';
import { useSelector } from 'store';
import { getEntities, truncateString } from 'lib/helper';
import type { UploadButtonProps } from 'components/common/Attachments/AttachmentsActions';
import type { NewSurveyRef, SurveyData } from 'components/Feed/Survey/NewSurvey';
import PostScheduling from 'components/layout/Common/Modal/PostScheduling/PostScheduling';
import ShowMore from 'components/layout/Main/Posts/ShowMore/ShowMore';
import { PostDate } from 'components/layout/Main/Posts/PostHeader/PostHeader';
import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';
import { Container as SurveyContainer, Fieldset } from 'components/Feed/Survey/Styles';
import PostEditorPlugin from '../PostEditorPlugin/PostEditorPlugin';
import { useComposerStore } from '../composerContext';
import * as S from './PostEditorStyles';

import { Modal } from '../../../Groups/Modal/CreateGroupModal/CreateGroupModalStyles';

const FieldsetSurvey = styled(Fieldset)`
  padding: 2em 0.8em;
  ${({ theme }) => theme.typography.fontSmall(1)};

  .survey-body {
    margin-bottom: 0.7em;
  }

  .survey-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.gray8};
    ${({ theme }) => theme.typography.fontxSmall()};

    b {
      color: ${({ theme }) => theme.colors.primary};
    }

    svg {
      margin-right: 0.1em;
    }
  }
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 400px;
  margin: 0 auto;
  object-fit: contain;
`;

const GalleryControl = styled.button<{ direction: 'prev' | 'next' }>`
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza o conteúdo */
  width: 64px; /* Largura do botão */
  height: 64px; /* Altura do botão */
  border-radius: 50%; /* Torna o botão redondo */
  font-size: 48px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  opacity: 0.4;
  transition: all 0.3s ease;
  transform: translateY(-50%);
  transform-origin: center;

  ${({ direction }) =>
    direction === 'prev'
      ? css`
          left: 24px;
        `
      : css`
          right: 24px;
        `}

  &:hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageGalleryContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  aspect-ratio: 37 / 20;
`;

const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 0 0 4px;
`;

const ContainerPostPreview = styled.div`
  border: 1px solid #d9d9d9;
  padding: 14px;
  border-radius: 14px;
  background-color: #fff;
`;

const NewSurvey = loadable(() => import('components/Feed/Survey/NewSurvey'));

export type PostEditorProps = {
  onSubmitPost: (surveyInfo?: SurveyInfo) => void;
  profile: any;
  visibility: any;
  storeKey: any;
  profileRef: any;
  ref: any;
  profileName: any;
};

export default function PostEditor({
  onSubmitPost,
  visibility,
  profile,
  profileName,
}: PostEditorProps) {
  const { t } = useTranslation();
  const { networkId, networkSettings } = useAuth();

  const [
    { currentLink, mentionsOpen, suggestions, scheduleOpen, errorMessage, loadingPreview },
    setState,
  ] = useReducer(reducer, initialState);

  const sendPostError = useSelector(state => state.posts.error);
  const textDescriptionError = useSelector(state => state.posts.errorDescription);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [surveyData, setSurveyData] = useState<SurveyData | null>(null);

  const carouselRef = useRef<any>(null);

  function getFormattedHTML(editorState: EditorState): string {
    if (!editorState) {
      return '';
    }

    const contentState = editorState.getCurrentContent();

    const options = {
      defaultBlockTag: 'div',
      blockStyleFn: (block: ContentBlock) => {
        if (block.getType() === 'unstyled') {
          return {
            attributes: {
              style: 'margin:0; padding:0;',
            },
          };
        }
        return {};
      },
    };

    return stateToHTML(contentState, options);
  }
  const getPostDescriptionError = () => {
    if (textDescriptionError === 'Target users list cannot be empty for private posts') {
      // return t('default:post.addPersonSendPost');

      return <S.ErrorMessage>{t('default:post.addPersonSendPost')}</S.ErrorMessage>;

      // setState({
      //   errorMessage: t('default:post.addPersonSendPost'),
      // });
    } else {
      // return t('default:post.errorSend');
      return <S.ErrorMessage>{t('default:post.errorSend')}</S.ErrorMessage>;

      // setState({
      //   errorMessage: t('default:post.errorSend'),
      // });
    }
  };

  const surveyRef = useRef<NewSurveyRef>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [editorState, setEditorState] = useComposerStore(
    state => [state.editorState, state.setEditorState],
    shallow
  );
  const [attachments, setAttachments, removeAttachment, resetAttachments] = useComposerStore(
    state => [
      state.attachments,
      state.setAttachments,
      state.removeAttachment,
      state.resetAttachments,
    ],
    shallow
  );
  const attachmentType = useComposerStore(state => state.attachmentType);
  const setScheduleDate = useComposerStore(state => state.setScheduleDate);
  const [surveyOpen, setSurveyOpen] = useComposerStore(
    state => [state.surveyOpen, state.setSurveyOpen],
    shallow
  );
  const isSending = useComposerStore(state => state.isSending);

  const previewLink = useRef(
    debounce(350, async (link: string) => {
      setState({ loadingPreview: true });

      const previewApi = await import('lib/api/preview');
      const preview = await previewApi.previewLink(link);

      setState({ loadingPreview: false });

      if (!preview || typeof preview === 'number') {
        return;
      }

      setAttachments<'link'>([preview], 'link');
    })
  ).current;

  const getErrorMessage = (forbiddenExtensions: string[], maxFileSize: number) => {
    return t('default:files.forbiddenFiles', {
      extensions: forbiddenExtensions.join(', '),
      size: maxFileSize,
    });
  };

  const editorChangeHandler = (newEditorState: EditorState, linksList: string[]) => {
    const linkEntity = getEntities(newEditorState, 'LINK').shift();

    if (linkEntity || linksList.length > 0) {
      const url = linkEntity?.entity.getData().url ?? linksList.pop();

      if (url !== currentLink) {
        setState({ currentLink: url });
        previewLink(url);
      }
    } else {
      previewLink.cancel();
      if (attachmentType === 'link') {
        setState({ currentLink: '' });
        resetAttachments();
      }
    }

    setEditorState(newEditorState);
  };

  const fileUploaderHandler: UploadButtonProps['onFileUploaded'] = (
    acceptedFiles,
    fileType,
    rejectedFiles
  ) => {
    const { maxPhotosPermittedInPost, maxPostFileSize } = networkSettings;

    if (acceptedFiles.length > 0) {
      // resetPostError();
      setState({ errorMessage: null });

      const totalFileCount = acceptedFiles.length + attachments.length;
      let allowedFiles = acceptedFiles;

      if (totalFileCount >= maxPhotosPermittedInPost && fileType === 'image') {
        allowedFiles = acceptedFiles.slice(0, maxPhotosPermittedInPost - attachments.length);
      }

      const newFiles = allowedFiles.map<PostFile>(file => ({
        blob: file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
      }));

      setAttachments(newFiles, fileType);
    } else if (rejectedFiles && rejectedFiles.length > 0) {
      resetAttachments();

      if (fileType === 'video') {
        setState({
          errorMessage: t('default:files.videoSizeAttachment', {
            size: maxPostFileSize,
          }),
        });
      } else {
        setState({
          errorMessage: t('default:files.forbiddenAttachment'),
        });
      }
    }
  };

  const rejectedFileHandler = () => {
    resetAttachments();
    setState({
      errorMessage: getErrorMessage(
        networkSettings.forbiddenExtensions,
        networkSettings.maxPostFileSize
      ),
    });
  };

  const removeFileHandler = (attachment: PostFile) => {
    URL.revokeObjectURL(attachment.preview);

    removeAttachment(attachment.index as string);
  };

  const resetAttachmentsHandler = () => {
    resetAttachments();
    setState({ currentLink: '' });
  };

  const searchMentionsHandler = useRef(
    debounce(350, async ({ value }: { value: string }) => {
      const mentionApi = await import('lib/api/mention');
      const mentionResult = await mentionApi.getUserList({ name: value });

      if (!mentionResult.ok) return [];

      const mentionList = mentionResult.data;

      const suggestions = mentionList.map(mention => ({
        userId: mention.userId,
        name: mention.name,
      }));

      setState({ suggestions });
    })
  ).current;

  const scheduleHandler = (date: string) => {
    setScheduleDate(date);

    // Fazer esperar consolidar o estado antes de enviar a requisição
    setTimeout(() => {
      formRef.current?.requestSubmit();
    }, 100);
  };

  const onSubmit = (data: SurveyData | FormEvent<HTMLFormElement>) => {
    let surveyInfo: SurveyInfo | undefined = undefined;

    if ('preventDefault' in data) {
      data.preventDefault();
    } else {
      surveyInfo = {
        ...data,
        companyNetworkId: networkId,
        endDate: data.endDate!.format('YYYY-MM-DDTHH:mm:ss'),
      };
    }

    onSubmitPost(surveyInfo);
  };

  const canSend = editorState.getCurrentContent().getPlainText().trim().length > 0;

  const handlePreviewClick = () => {
    if (surveyRef.current) {
      const formData = surveyRef.current.getValues();
      setSurveyData({
        ...formData,
        endDate: formData.endDate?.format('YYYY-MM-DDTHH:mm:ss'),
        totalAnswers: 0,
        selectedUserOption: 0,
        anonymous: formData.anonymous,
        post: {},
      } as any);
    }
    setIsPreviewOpen(true);
  };

  const renderVisibility = (visibility: 'public' | 'private' | 'followers' | 'admin' | 'group') => {
    const icons = {
      public: faGlobe,
      private: faLock,
      followers: faUserFriends,
      admin: faUserShield,
    };

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {visibility !== 'group' && <S.Divider>-</S.Divider>}
        {visibility !== 'group' && <FontAwesomeIcon icon={icons[visibility]} />}
        <span style={{ marginLeft: '5px' }}>
          {t(`new-post.visibility.${visibility}`, { defaultValue: '' })}
        </span>
      </div>
    );
  };

  return (
    <>
      <div>
        <PostEditorPlugin
          editorProps={{
            editorState: editorState,
            editorDisabled: isSending,
            onTextChange: editorChangeHandler,
          }}
          attachmentProps={{
            attachmentsDisabled: surveyOpen || isSending,
            attachments,
            attachmentType,
            loading: loadingPreview,
            onFileUploaded: fileUploaderHandler,
            onFileRejected: rejectedFileHandler,
            onFileRemoved: removeFileHandler,
            resetAttachments: resetAttachmentsHandler,
          }}
          surveyProps={{
            surveyDisabled: isSending,
            onSurveyClick: () => setSurveyOpen(!surveyOpen),
          }}
          mentionProps={{
            mentionsOpen,
            mentionSuggestions: suggestions,
            onMentionSearch: searchMentionsHandler,
            onMentionOpen: open => {
              setState(({ suggestions }) => ({
                mentionsOpen: open,
                suggestions: !open ? [] : suggestions,
              }));
            },
          }}
        />

        {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        {sendPostError && getPostDescriptionError()}

        <form onSubmit={surveyRef.current?.handleSubmit(onSubmit) ?? onSubmit} ref={formRef}>
          {surveyOpen && (
            <NewSurvey
              isValid
              isOwnForm={false}
              onSave={() => {}}
              onCancel={() => {
                setSurveyOpen(false);
                setSurveyData(null);
              }}
              ref={surveyRef}
            />
          )}
          <SubmitPanel
            canSend={canSend}
            onSchedule={() => setState({ scheduleOpen: true })}
            onPreview={handlePreviewClick}
          />
        </form>
      </div>
      <PostScheduling
        show={scheduleOpen}
        loading={isSending}
        onDateSelected={scheduleHandler}
        onClose={() => setState({ scheduleOpen: false })}
      />
      <Modal
        title="Preview"
        visible={isPreviewOpen}
        width={738}
        onCancel={() => setIsPreviewOpen(false)}
        bodyStyle={{ backgroundColor: '#fafafa' }}
        footer={[
          <Button key="close" onClick={() => setIsPreviewOpen(false)}>
            Fechar
          </Button>,
        ]}
      >
        <ContainerPostPreview>
          <S.ContainerPreviewHeader>
            <ProfileImage profile={profile} skipOnlineCheck={true} />
            <S.PreviewHeaderWrapper>
              <S.NameTitle>{profileName}</S.NameTitle>
              <S.PreviewHeaderInfo>
                <PostDate date={new Date()} />
                <div>{renderVisibility(visibility)}</div>
              </S.PreviewHeaderInfo>
            </S.PreviewHeaderWrapper>
          </S.ContainerPreviewHeader>
          <ShowMore textContent={getFormattedHTML(editorState)} charactersCount={700} />
          {surveyData && (
            <SurveyContainer>
              <h3>{surveyData.title}</h3>
              <FieldsetSurvey>
                <div className="survey-body">
                  {surveyData.options.map((option, index) => (
                    <S.SurveyOption key={index}>
                      <span>{option.description}</span>
                    </S.SurveyOption>
                  ))}
                </div>
              </FieldsetSurvey>
            </SurveyContainer>
          )}
          {attachments && attachments.length > 0 && (
            <div style={{ marginTop: '16px' }}>
              {attachmentType === 'file' ? (
                attachments.map((attachment, index) => (
                  <>
                    <FontAwesomeIcon icon={faPaperclip} />
                    <FileName>{truncateString(attachment.name, 44)}</FileName>
                  </>
                ))
              ) : attachments.length > 1 ? (
                <ImageGalleryContainer>
                  <Wrapper>
                    <Carousel ref={carouselRef} dots={false} infinite={false}>
                      {attachments.map((attachment, index) => {
                        const isVideo = attachment.blob?.type?.startsWith('video');
                        return (
                          <div key={index}>
                            {isVideo ? (
                              <video
                                controls
                                src={
                                  attachment.preview ||
                                  (attachment.blob && URL.createObjectURL(attachment.blob))
                                }
                                style={{ maxWidth: '100%', margin: '0 auto' }}
                              >
                                Seu navegador não suporta a tag video
                              </video>
                            ) : (
                              <Img
                                src={
                                  attachment.preview ||
                                  (attachment.blob && URL.createObjectURL(attachment.blob))
                                }
                                alt={`Attachment ${index + 1}`}
                              />
                            )}
                          </div>
                        );
                      })}
                    </Carousel>
                    <GalleryControl
                      type="button"
                      className="fa-layers"
                      direction="prev"
                      onClick={() => carouselRef.current?.prev()}
                    >
                      <FontAwesomeIcon icon={faCircle} />
                      <FontAwesomeIcon icon={faChevronLeft} inverse transform="shrink-10" />
                    </GalleryControl>
                    <GalleryControl
                      type="button"
                      className="fa-layers"
                      direction="next"
                      onClick={() => carouselRef.current?.next()}
                    >
                      <FontAwesomeIcon icon={faCircle} />
                      <FontAwesomeIcon icon={faChevronRight} inverse transform="shrink-10" />
                    </GalleryControl>
                  </Wrapper>
                </ImageGalleryContainer>
              ) : (
                attachments.map((attachment, index) => {
                  if (attachment.type) {
                    return null;
                  }
                  const isVideo = attachment.blob?.type?.startsWith('video');
                  return isVideo ? (
                    <video
                      key={index}
                      controls
                      src={
                        attachment.preview ||
                        (attachment.blob && URL.createObjectURL(attachment.blob))
                      }
                      style={{ maxWidth: '100%', marginBottom: '8px' }}
                    >
                      Seu navegador não suporta a tag video
                    </video>
                  ) : (
                    <Img
                      key={index}
                      src={
                        attachment.preview ||
                        (attachment.blob && URL.createObjectURL(attachment.blob))
                      }
                      alt={`Attachment ${index + 1}`}
                      style={{ maxWidth: '100%', marginBottom: '8px' }}
                    />
                  );
                })
              )}
            </div>
          )}
        </ContainerPostPreview>
      </Modal>
    </>
  );
}

function SubmitPanel({
  canSend,
  onSchedule,
  onPreview,
}: {
  canSend: boolean;
  onSchedule: () => void;
  onPreview: () => void;
}) {
  const { t } = useTranslation('feed');

  const { networkFunctions } = useAuth();
  const [canCreatePostSchedule] = usePermissions('CreatePostSchedule');

  const isSending = useComposerStore(state => state.isSending);

  return (
    <S.SubmitWrapper>
      <Button
        disabled={!canSend}
        loading={isSending}
        block
        type="primary"
        htmlType="submit"
        size="large"
      >
        {t('editor.post')}
      </Button>
      <Tooltip
        placement="top"
        title={'Aproveite a pré-visualização para conferir suas publicações antes de postar!'}
      >
        <Button
          id="post_preview"
          size="large"
          type="default"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={onPreview}
        >
          <FontAwesomeIcon icon={faEye} style={{ marginRight: '8px' }} />
          Preview
          <S.Highlight>
            <span>Novo</span>
          </S.Highlight>
        </Button>
      </Tooltip>
      {networkFunctions.postScheduling && canCreatePostSchedule && (
        <Button
          disabled={!canSend || isSending}
          size="large"
          htmlType="button"
          icon={<FontAwesomeIcon icon={faCalendar} />}
          onClick={onSchedule}
          style={{
            minWidth: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      )}
    </S.SubmitWrapper>
  );
}

type State = typeof initialState;
type StateValue = Partial<State> | ((prevState: State) => Partial<State>);

const initialState = {
  mentionsOpen: false,
  scheduleOpen: false,
  currentLink: '',
  suggestions: [] as MentionData[],
  errorMessage: null as null | string,
  loadingPreview: false,
  sendPostError: false,
  resetPosts,
};

function reducer(state: State, value: StateValue) {
  if (typeof value === 'function') {
    return { ...state, ...value(state) };
  }

  return { ...state, ...value };
}
