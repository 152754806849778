import styled from '@emotion/styled';

export const SubmitWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 1em;
`;

export const ErrorMessage = styled.span`
  display: block;
  margin-block: 0.5em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.danger};

  ${({ theme }) => theme.typography.fontxSmall('20px')}
`;

export const SurveyOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;

  :hover {
    border-color: ${({ theme }) => theme.colors.primary4};
  }
  :active,
  :focus {
    border-color: ${({ theme }) => theme.colors.primary6};
  }

  & + & {
    margin-top: 1em;
  }

  .content {
    display: inline-flex;
    z-index: 1;
  }

  .content span {
    margin: 0 0 0 3px;
    width: 50px;
  }

  .descOption {
    line-height: 1.2em;
    text-align: justify;
    padding: 2px 12px 2px 3px;
  }
`;

export const NameTitle = styled.h4`
  display: inline-block;
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.typography.fontSmall('20px')}
`;

export const PreviewHeaderWrapper = styled.div`
  flex: 1;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.gray9};
`;

export const Divider = styled.div`
  margin: 0 8px;
`;

export const PreviewHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray9};

  ${({ theme }) => theme.typography.fontSmall('20px')};

  a {
    color: ${({ theme }) => theme.colors.gray9};
  }
`;

export const ContainerPreviewHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

export const Highlight = styled.div`
  margin: 0 0 0 5px;
  width: 65px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 21px;
  font-size: 14px;
  color: #fff;
`;
