import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert, Layout, Skeleton, Button } from 'antd';
import { ToastContainer, toast, ToastContentProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import loadable from '@loadable/component';

import AppHeader from 'components/layout/AppHeader/AppHeader';
import NavigationMenu from 'components/layout/LeftSide/NavigationMenu/NavigationMenu';
import ChatList from 'components/layout/Chat/ChatList';
import TermsModal from 'components/modal/TermsModal/TermsModal';

import { useAuth, useFeatureToggle, usePermissions } from 'hooks';
import { useSelector } from 'store';
import { canUseFunction } from 'store/selectors/authSelectors';

import Feed from 'pages/Feed/Feed';
import LoginSeniorX from 'pages/SeniorX/LoginSeniorX';
import AuthenticateCas from 'pages/CAS/AuthenticateCas';

import Messaging from './Messaging';
import { refreshCacheAndReload } from '../../CacheBuster';
import { useOnline } from './useOnline';
import { usePushNotification } from './usePushNotification';
import * as S from './OnlineStyles';

// #region PAGES
const SinglePost = lazy(() => import('pages/SinglePost/SinglePost'));
const Users = lazy(() => import('pages/Users'));
const Groups = lazy(() => import('pages/Groups'));
const SavedItems = lazy(() => import('pages/SavedItems'));
const Admin = lazy(() => import('pages/Admin'));
const NetworkManagement = lazy(() => import('pages/NetworkManagement/NetworkManagement'));
const EmailResetPassword = lazy(() => import('pages/Email/ResetPassword'));
const EmailWelcome = lazy(() => import('pages/Email/Welcome'));
const EmailPublicPost = lazy(() => import('pages/Email/PublicPost'));
const EmailPostMention = lazy(() => import('pages/Email/PostMention'));
const EmailCommentMention = lazy(() => import('pages/Email/CommentMention'));
const EmailNewComment = lazy(() => import('pages/Email/NewComments'));
const EmailVideoConverted = lazy(() => import('pages/Email/VideoConverted'));
const EmailPostPublished = lazy(() => import('pages/Email/PostPublished'));
const EmailGroupInvite = lazy(() => import('pages/Email/GroupInvite'));
const Legal = lazy(() => import('pages/Legal'));
// #endregion

const SecurityModal = loadable(
  () => import(/* webpackChunkName: "SecurityModal" */ 'components/modal/SecurityModal')
);

//#region TYPES
type PrivateRouteProps = {
  permission: boolean;
  element: JSX.Element;
};
interface MenuSkeletonProps {
  loading: boolean;
}
//#endregion

function Online() {
  const location = useLocation();
  const chatFunction = useSelector(state => canUseFunction(state, 'chat'), shallowEqual);
  const { token, userId, isSystemMasterAdmin } = useAuth();
  const [createBanner, manageRoles, manageNetwork, manageUsers] = usePermissions(
    'CreateBanner',
    'ManageRoles',
    'ManageNetwork',
    'ManageUsers'
  );
  const [enableNewLoginToken] = useFeatureToggle('new_login_token');

  const checkManagement = createBanner || manageRoles || manageNetwork || manageUsers;

  useOnline();
  usePushNotification({
    onNewAppVersion() {
      toast(<UpdateMessage />, {
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        position: 'bottom-left',
        toastId: 'update-app',
      });
    },
  });

  const canRender = !!token && !!userId;

  if (location.pathname === '/loginSeniorX') {
    return <LoginSeniorX />;
  }

  const renderRoutes = () => {
    const spinner = (
      <FontAwesomeIcon
        icon={faSpinnerThird}
        spin
        size="3x"
        style={{
          alignSelf: 'center',
        }}
      />
    );

    if (canRender) {
      return (
        <Suspense fallback={<div />}>
          <Routes>
            <Route path="/feed" element={<Feed />} />
            <Route path="/post/:postId" element={<SinglePost />} />
            <Route path="/users/*" element={<Users />} />
            <Route path="/groups/*" element={<Groups />} />
            <Route path="/bookmarks" element={<SavedItems />} />
            <Route path="/email">
              <Route path="reset-password" element={<EmailResetPassword />} />
              <Route path="welcome" element={<EmailWelcome />} />
              <Route path="public-post" element={<EmailPublicPost />} />
              <Route path="post-mention" element={<EmailPostMention />} />
              <Route path="comment-mention" element={<EmailCommentMention />} />
              <Route path="new-comments" element={<EmailNewComment />} />
              <Route path="video-converted" element={<EmailVideoConverted />} />
              <Route path="post-published" element={<EmailPostPublished />} />
              <Route path="group-invite" element={<EmailGroupInvite />} />
            </Route>
            <Route path="/legal/*" element={<Legal />} />
            <Route path="/authenticateCas" element={<AuthenticateCas />} />
            <Route
              path="/admin/*"
              element={<PrivateRoute permission={checkManagement} element={<Admin />} />}
            />
            <Route
              path="/networkmanagement"
              element={
                <PrivateRoute permission={isSystemMasterAdmin} element={<NetworkManagement />} />
              }
            />
            <Route path="*" element={<Navigate to="/feed" replace />} />
          </Routes>
        </Suspense>
      );
    }

    return spinner;
  };

  const renderChat = () => {
    if (canRender && chatFunction) {
      return <ChatList />;
    }
    return null;
  };

  return (
    <>
      <AppHeader loading={!canRender} />
      <Layout css={S.container}>
        <S.MenuContainer>
          <MenuSkeleton loading={!canRender}>
            <NavigationMenu />
          </MenuSkeleton>
        </S.MenuContainer>
        <div css={S.mainContent(!canRender)} id="main-content">
          {renderRoutes()}
        </div>
      </Layout>
      {renderChat()}
      <S.beeToastNotify>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          progressClassName="bee-toast-newversion"
          theme="colored"
          style={{ width: '464px', padding: '0', marginLeft: '52px' }}
        />
      </S.beeToastNotify>
      <Messaging />
      <TermsModal />
      {canRender && enableNewLoginToken && <SecurityModal />}
    </>
  );
}

export default Online;

function PrivateRoute({ element, permission }: PrivateRouteProps) {
  const location = useLocation();

  return permission ? element : <Navigate to="/feed" state={{ from: location }} replace />;
}

const MenuSkeleton: React.FunctionComponent<MenuSkeletonProps> = ({ children, loading }) => {
  return loading ? (
    <ul css={S.ul}>
      <li>
        <Skeleton.Avatar active shape="square" />
      </li>
      <li>
        <Skeleton.Avatar active shape="square" />
      </li>
      <li>
        <Skeleton.Avatar active shape="square" />
      </li>
    </ul>
  ) : (
    <>{children}</>
  );
};

const UpdateMessage = (props: Partial<ToastContentProps>) => {
  const { t } = useTranslation();

  const handleUpdate = () => {
    refreshCacheAndReload();

    props.closeToast!();
  };

  return (
    <div>
      <Alert
        message={t('newSystemVersion')}
        description={t('updateAvailable')}
        type="info"
        icon={<FontAwesomeIcon icon={faCircleInfo} style={{ color: '#002c8c' }} />}
        showIcon
        className={S.toastCss}
        style={{ borderRadius: '8px' }}
        action={
          <Button size="small" type="text" onClick={handleUpdate}>
            {t('update')}
          </Button>
        }
      />
    </div>
  );
};
